
import { computed, defineComponent, inject, reactive } from 'vue'
import AppHead from '../components/head.vue'
import { notifyInstance } from '../../../type'
import axios from '@/http'
import { useStore } from 'vuex'
import Clipboard from 'clipboard'

export default defineComponent({
  components: {
    'app-head': AppHead
  },
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const globalVariable = computed(() => store.getters.globalVariable)
    const paymentsMap = computed(() => globalVariable.value.payments)

    const paymentTypes = reactive([] as any)
    const enablePayments = reactive([] as any)

    const storeUid = computed(() =>
      nowStore.value.uuid.replace(/-/g, '').substring(0, 20)
    )

    function init() {
      axios
        .get(`/system/payments/${storeId.value}`)
        .then((res) => {
          Promise.all([
            Object.assign(paymentTypes, res.data.paymentTypes),
            Object.assign(enablePayments, res.data.enablePayments)
          ]).then(function () {
            paymentTypes.forEach((paymentType: { payments: any }) => {
              const payments = paymentType.payments
              payments.forEach(function (payment: {
                id: string
                isEnable: boolean
                setting: []
                info: string
              }) {
                if (payment.id in enablePayments) {
                  payment.isEnable = enablePayments[payment.id].isEnable
                  payment.setting.forEach(
                    (setting: {
                      key: string
                      value: string
                      isPassword: boolean
                    }) => {
                      if (
                        enablePayments[payment.id].setting &&
                        setting.key in enablePayments[payment.id].setting
                      ) {
                        setting.value = setting.isPassword
                          ? ''
                          : enablePayments[payment.id].setting[setting.key]
                      } else {
                        setting.value = ''
                      }
                    }
                  )
                } else {
                  payment.isEnable = false
                }
              })
            })
          })
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `初始化失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function changePaymentEnable(event: any) {
      const checker = event.target.checked
      const id = event.target.getAttribute('data-payment-id')
      axios
        .patch(`/system/payments/${storeId.value}`, {
          paymentId: id,
          isEnable: checker
        })
        .then((res) => {
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function updateSetting(payment: any) {
      axios
        .patch(`/system/payments/${storeId.value}/setting`, {
          paymentId: payment.id,
          settings: payment.setting
        })
        .then((res) => {
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function copyStoreUid() {
      const clipboard = new Clipboard('.js_copy_store')
      clipboard.on('success', () => {
        clipboard.destroy()
        notify(
          {
            classType: 'success',
            message: '複製成功',
            closeAble: false
          },
          3000
        )
      })

      clipboard.on('error', () => {
        clipboard.destroy()
        notify(
          {
            classType: 'success',
            message: '複製失敗',
            closeAble: false
          },
          3000
        )
      })
    }

    init()

    return {
      paymentTypes,
      changePaymentEnable,
      paymentsMap,
      enablePayments,
      updateSetting,
      storeUid,
      copyStoreUid
    }
  }
})
