
import { computed, defineComponent, inject, reactive, ref } from 'vue'
import Fixed from '@/views/components/fixed.vue'
import AppHead from '../components/head.vue'
import {
  storeInvoiceSetting,
  trackListInfo,
  deviceInvoiceSetting,
  modalInstance,
  notifyInstance,
  device
} from '@/type'
import axios from '@/http'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    'app-head': AppHead,
    Fixed
  },
  setup() {
    const modal = inject('modal') as modalInstance
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const router = useRouter()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const storeInvoiceSetting = reactive({} as storeInvoiceSetting)
    const trackListInfo = reactive({} as trackListInfo)
    const deviceInvoiceSettings = ref(new Array<deviceInvoiceSetting>())
    const propTitle = ref('電子發票管理')
    const isEnable = ref(false)
    const isDeviceEnable = ref(false)
    const devices = ref(new Array<device>())

    const invoiceCount = computed(() => {
      return trackListInfo.InvoiceCount || 0
    })

    const invoiceTrackCount = computed(() => {
      return trackListInfo.Invoice_trackCount || 0
    })

    const invoiceTotalUseful = computed(() => {
      return trackListInfo.Total_UsefulInvoice || 0
    })

    function getDeviceName(deviceId: string) {
      const device = devices.value.find((device: device) => {
        return device.ipadId === deviceId
      })
      if (device !== undefined) {
        return device.name
      }
      return ''
    }

    function init() {
      const initSetting = axios
        .get(`/system/invoiceSetting/${storeId.value}`)
        .then((res) => {
          Object.assign(storeInvoiceSetting, res.data.storeInvoiceSetting)
          Object.assign(
            deviceInvoiceSettings.value,
            res.data.deviceInvoiceSettings
          )

          if (storeInvoiceSetting.id !== null) {
            isEnable.value = true
          }
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `初始化失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })

      const initDevices = axios
        .get(`/devices/${storeId.value}`)
        .then((res) => {
          const data = res.data
          devices.value = data.devices
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })

      Promise.all([initSetting, initDevices]).then(function () {
        axios
          .get(`/system/getTrackListInfo/${storeId.value}`)
          .then((res) => {
            Object.assign(trackListInfo, res.data.trackListInfo)
          })
          .catch((err) => {
            console.log(trackListInfo)
            console.log(err)
          })
      })
    }

    function save() {
      axios
        .patch(`/system/invoiceSetting/${storeId.value}`, {
          type: 'devices',
          deviceInvoiceSettings: deviceInvoiceSettings.value
        })
        .then((res) => {
          router.push({
            name: 'refresh'
          })
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      init()
    }

    function changeStoreInvoiceEnable(event: any) {
      const checker = event.target.checked
      deviceInvoiceSettings.value.forEach(
        (deviceInvoiceSetting: deviceInvoiceSetting) => {
          deviceInvoiceSetting.show = checker
          deviceInvoiceSetting.isEnable = checker
        }
      )
    }

    // 模擬呼叫外部api驗證後儲存
    function validateSetting() {
      axios
        .patch(`/system/invoiceSetting/${storeId.value}`, {
          type: 'store',
          account: storeInvoiceSetting.account,
          password: storeInvoiceSetting.password
        })
        .then((res) => {
          modal({
            cancelBtn: false,
            content: '驗證成功',
            confirmBtnText: '確定',
            confirmBtnClick: () => {
              return new Promise((resolve, reject) => {
                router.push({
                  name: 'refresh'
                })
                resolve('work')
              })
            },
            cancelBtnClick: () => {
              return new Promise((resolve, reject) => {
                router.push({
                  name: 'refresh'
                })
                resolve('work')
              })
            }
          }).show()
        })
        .catch((err) => {
          modal({
            classType: 'error',
            cancelBtn: false,
            content: `儲存失敗 ${err.response.errors.detail}`,
            confirmBtnText: '確定',
            confirmBtnClick: () => {
              return new Promise((resolve, reject) => {
                resolve('work')
              })
            }
          }).show()
        })
        .catch((err) => {
          console.log(err)
          modal({
            classType: 'error',
            cancelBtn: false,
            content: '驗證失敗',
            confirmBtnText: '確定',
            confirmBtnClick: () => {
              return new Promise((resolve, reject) => {
                resolve('work')
              })
            }
          }).show()
        })
    }

    init()

    return {
      save,
      cancel,
      storeInvoiceSetting,
      deviceInvoiceSettings,
      changeStoreInvoiceEnable,
      validateSetting,
      propTitle,
      isEnable,
      isDeviceEnable,
      getDeviceName,
      trackListInfo,
      invoiceCount,
      invoiceTrackCount,
      invoiceTotalUseful
    }
  }
})
