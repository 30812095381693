<template>
  <teleport to=".l-container" v-if="show">
    <div class="l-fixedBar">
      <div class="l-fixedBar__wrap">
        <div class="l-fixedBar__main">
          <div class="l-actions">
            <div class="l-actions__item">
              <button
                type="button"
                class="c-btn c-btn--primary"
                @click="emit('save')"
              >
                <span>儲存</span>
              </button>
            </div>
            <div class="l-actions__item">
              <button
                type="button"
                class="c-btn c-btn--secondary"
                @click="emit('cancel')"
              >
                <span>取消</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  emits: ['save', 'cancel'],
  setup(props, { emit }) {
    const show = ref(false)
    onMounted(() => {
      show.value = true
    })
    return {
      emit,
      show
    }
  }
})
</script>
