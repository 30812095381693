
import { defineComponent, ref, reactive, inject, computed } from 'vue'
import AppHead from '../components/head.vue'
import Fixed from '@/views/components/fixed.vue'
import { notifyInstance, storePrinter } from '@/type'
import { useStore } from 'vuex'
import axios from '@/http'

export default defineComponent({
  components: {
    'app-head': AppHead,
    Fixed
  },
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const propTitle = ref('出單機設定')
    const orderListQuantitySetting = reactive({
      min: 1,
      max: 10
    })

    const storePrinter = reactive({} as storePrinter)

    function save() {
      axios
        .patch(`/system/printer/${storeId.value}`, {
          type: 'printer',
          storePrinter: storePrinter
        })
        .then((res) => {
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      init()
    }

    function init() {
      axios
        .get(`/system/printer/${storeId.value}`)
        .then((res) => {
          Object.assign(storePrinter, res.data.storePrinter)
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `初始化失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function addPrinterOrderListCount() {
      if (storePrinter.orderListQuantity! < orderListQuantitySetting.max) {
        storePrinter.orderListQuantity! += 1
      }
    }

    function minusPrinterOrderListCount() {
      if (storePrinter.orderListQuantity! > orderListQuantitySetting.min) {
        storePrinter.orderListQuantity! -= 1
      }
    }

    init()

    return {
      propTitle,
      save,
      cancel,
      storePrinter,
      orderListQuantitySetting,
      addPrinterOrderListCount,
      minusPrinterOrderListCount
    }
  }
})
